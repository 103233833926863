import React from "react";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

export function checkValueExistencyFilterCell(): React.FC<GridFilterCellProps> {
	const CheckValueExistencyFilterCell = (props: GridFilterCellProps) => {
		const { t } = useTranslation();
		function onChange(event: DropDownListChangeEvent): void {
			const selectedValue: string = event.target.value;
			setValue(selectedValue, event.syntheticEvent);
		}

		function setValue(selectedValue: string, syntheticEvent: React.SyntheticEvent): void {
			if (selectedValue === t("grid.filterBooleanAll")) {
				props.onChange({
					value: "",
					operator: "",
					syntheticEvent
				});
			} else {
				props.onChange({
					value: null,
					operator: selectedValue === t("grid.filterIsTrue") ? "isnotnull" : "isnull",
					syntheticEvent
				});
			}
		}

		let value: string = t("grid.filterBooleanAll");
		if (props.value === null) {
			value = props.operator === "isnotnull" ? t("grid.filterIsTrue") : t("grid.filterIsFalse");
		}

		return (
			<div className="k-filtercell">
				<DropDownList data={[t("grid.filterBooleanAll"), t("grid.filterIsTrue"), t("grid.filterIsFalse")]} onChange={onChange} value={value} defaultValue={t("grid.filterBooleanAll")} />
				<div className="k-filtercell-operator">
					<button
						className={"k-button k-button-icon" + (props.value === null && " k-clear-button-visible")}
						title="Maak leeg"
						type="button"
						onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setValue(t("grid.filterBooleanAll"), event)}
					>
						<span className="k-icon k-i-filter-clear" />
					</button>
				</div>
			</div>
		);
	};

	return CheckValueExistencyFilterCell;
}

export default checkValueExistencyFilterCell;
