import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import get from "lodash/get";

import { cellContentsFunction } from ".";

export function checkBoxCell(fieldFilledInCheck: boolean): cellContentsFunction {
	const CheckBoxCell = (props: GridCellProps) => {
		let checked: boolean = props.dataItem[props.field];
		if (fieldFilledInCheck) {
			const valueToCheck = get(props.dataItem, props.field);
			checked = valueToCheck !== null && valueToCheck !== undefined;
		}

		return <Checkbox checked={checked} label="" />;
	};

	return CheckBoxCell;
}
