import React from "react";

interface IProps {
	clickClose: (e: React.MouseEvent) => void;
	clickNext: (e: React.MouseEvent) => void;
	clickPrevious: (e: React.MouseEvent) => void;
	first: boolean;
	last: boolean;
	src: string;
}

const ImageGalleryModal: React.FC<IProps> = (props: IProps) => {
	return (
		<>
			<div className="image-galery-overlay" onClick={props.clickClose} />
			<div className="image-galery-modal d-flex align-items-center justify-content-center">
				<div className="body">
					<div className="button close" onClick={props.clickClose}>
						<i className="las la-times" />
					</div>
					{!props.first && (
						<div className="button prev" onClick={props.clickPrevious}>
							<i className="las la-angle-left" />
						</div>
					)}
					{!props.last && (
						<div className="button next" onClick={props.clickNext}>
							<i className="las la-angle-right" />
						</div>
					)}
					<img src={props.src} alt={props.src} />
				</div>
			</div>
		</>
	);
};

export default ImageGalleryModal;
