import { Notification } from "react-notification-system";
import { show } from "react-notification-system-redux";
import { Action } from "redux";

function notify(
	title: string | React.ReactElement,
	message: string | React.ReactElement,
	level: "success" | "error" | "warning" | "info",
	dismissible = true,
	position: "tr" | "tl" | "tc" | "br" | "bl" | "bc" = "tl",
	autoDismiss = 7
): Action {
	const newConfig: Notification = {
		title,
		message,
		dismissible,
		position,
		autoDismiss
	};
	return show(newConfig, level);
}

export { notify };
