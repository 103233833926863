import React from "react";

import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { TagData } from "@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import EnumMultiSelect from "../../../editor/enumMultiSelect";

// eslint-disable-next-line
export function enumMultiSelectFilterCell<T>(data: any): React.FC<GridFilterCellProps> {
	const EnumMultiSelectFilterCell = (props: GridFilterCellProps) => {
		const { t } = useTranslation();

		function hasValue(valueToCheck: T[]): boolean {
			return Boolean(valueToCheck && valueToCheck.length > 0);
		}

		function onMultiSelectChange(event: MultiSelectChangeEvent): void {
			const valueSelected: boolean = hasValue(event.target.value);
			props.onChange({
				value: valueSelected ? map(event.target.value, "key") : "",
				operator: valueSelected ? "in" : "",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: "",
				operator: "",
				syntheticEvent: event
			});
		}

		const selectedValue: T[] = props.value || [];

		return (
			<div className="k-filtercell">
				<EnumMultiSelect
					enum={data}
					onChange={onMultiSelectChange}
					value={selectedValue}
					tags={selectedValue && selectedValue.length > 0 ? [{ text: `${selectedValue.length} ${t("itemsSelected")}`, data: [...selectedValue] }] : []}
					tagRender={(_tagData: TagData, li: React.ReactElement<HTMLLIElement>) => React.cloneElement(li, li.props, li.props.children[0])}
				/>
				<button
					className="k-button k-button-icon k-clear-button-visible k-filtercell-operator"
					title={t("grid.filterClearButton")}
					type="button"
					disabled={!hasValue(props.value)}
					onClick={onClearButtonClick}
				>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		);
	};
	return EnumMultiSelectFilterCell;
}

export default enumMultiSelectFilterCell;
