import React, { Dispatch, useEffect, useState } from "react";

import { Dialog, DialogActionsBar, DialogProps } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { ApiCommunicator, useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { hideLoader, IEntityState, showLoader } from "@selas/state-management";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import StandardButton from "../buttons/standardButton";
import SubmitButton from "../buttons/submitButton";
import Confirm from "../confirm";
import { usePreventWindowUnload } from "../hooks";
import { notify } from "../notification";
import Form from "./form";
import { resetIdsForNewEntities } from "./utils";

interface IProps<T extends IEntity> {
	record: T;
	endpoint: string;
	extraUrlParams?: Record<string, unknown>;
	entityState: IEntityState<T>;
	dispatch: Dispatch<IAction>;
	dataChanged: boolean;
	recordName: string;
	entityType?: string;
	readonly: boolean;
	getErrorMessages: () => string[];
	close: (record?: T) => void;
	firstFieldRef?: React.MutableRefObject<Input>;
}

function EntityEditor<T extends IEntity>({
	record,
	endpoint,
	extraUrlParams,
	entityState,
	dispatch,
	dataChanged,
	recordName,
	entityType,
	readonly,
	getErrorMessages,
	close,
	firstFieldRef,
	children,
	...dialogProps
}: React.PropsWithChildren<IProps<T>> & DialogProps): React.ReactElement {
	const { t } = useTranslation();
	const [askSaveChange, setAskSaveChange] = useState(false);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (record.id) {
			apiService.callApi(dispatch, endpoint, "GET", { id: record.id, ...extraUrlParams });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (firstFieldRef && firstFieldRef.current) {
			firstFieldRef.current.focus();
		}
	}, [firstFieldRef]);

	useEffect(() => {
		if (!record.id && entityState.addedEntity) {
			close(entityState.addedEntity);
		} else if (record.id && entityState.updatedEntity) {
			close(entityState.updatedEntity);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityState.addedEntity, entityState.updatedEntity]);

	useEffect(() => {
		if (entityState.isEntityLoading || entityState.isAdding || entityState.isUpdating) {
			reduxDispatch(showLoader());
		} else if (!(entityState.isEntityLoading || entityState.isAdding || entityState.isUpdating)) {
			reduxDispatch(hideLoader());
		}
		return () => reduxDispatch(hideLoader());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityState.isEntityLoading, entityState.isAdding, entityState.isUpdating]);

	function isEntityArray(toBeDetermined: unknown[]): toBeDetermined is IEntity[] {
		return Array.isArray(toBeDetermined) && toBeDetermined.length > 0 && (toBeDetermined[0] as IEntity).id !== undefined;
	}

	function save(): void {
		const errorMessages: string[] = getErrorMessages();
		if (!errorMessages || errorMessages.length <= 0) {
			const newRecord: T = cloneDeep(record);
			for (const [, value] of Object.entries(newRecord)) {
				if (isEntityArray(value)) {
					resetIdsForNewEntities(value);
				}
			}
			if (!newRecord.id) {
				apiService.callApi(dispatch, endpoint, "POST", { ...extraUrlParams }, newRecord);
			} else {
				apiService.callApi(dispatch, endpoint, "PUT", { id: newRecord.id, ...extraUrlParams }, newRecord);
			}
		} else {
			reduxDispatch(
				notify(
					t("error"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"error"
				)
			);
		}
	}

	let action: string = t("add");
	if (record.id) {
		action = t("edit");
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	return (
		<>
			<Form>
				<Dialog {...dialogProps} title={`${action} ${entityType} ${recordName}`.trim()} onClose={() => handleClose()}>
					{children}
					<DialogActionsBar>
						<StandardButton onClick={() => handleClose()} type="button">
							{readonly ? t("close") : t("cancel")}
						</StandardButton>
						{!readonly && (
							<SubmitButton primary disabled={!dataChanged} onClick={() => save()}>
								{t("save")}
							</SubmitButton>
						)}
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
}

export default EntityEditor;
