import React, { useEffect, useState } from "react";
import { Link, NavLink, NavLinkProps, Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from "react-router-dom";

import each from "lodash/each";

export interface IRoutedTabProps {
	label: string;
	reactKey: string;
	url: string;
	tileIcon?: string;
	tileLabel?: string;
	isAllowed?: boolean;
}

interface IProps extends RouteComponentProps {
	title?: string;
	image?: string;
	loadFirstTab?: boolean;
	children: React.ReactElement<IRoutedTabProps>[] | React.ReactElement<IRoutedTabProps>;
}

function getTabLink(parentUrl: string, child: IRoutedTabProps): React.ReactElement<NavLinkProps> {
	return (
		<NavLink to={parentUrl + child.url} key={child.reactKey} activeClassName="active">
			<li>{child.label}</li>
		</NavLink>
	);
}

function getTileLink(parentUrl: string, child: IRoutedTabProps): React.ReactElement<NavLinkProps> {
	return (
		<Link to={parentUrl + child.url} key={child.reactKey}>
			<div className="tile d-flex flex-column align-items-center">
				<div className="flex-grow-1 d-flex align-items-center">
					<i className={child.tileIcon} />
				</div>
				<span>{child.tileLabel || child.label}</span>
			</div>
		</Link>
	);
}

function getRoute(parentUrl: string, child: React.ReactElement<IRoutedTabProps>): React.ReactElement<RouteProps> {
	return <Route key={child.props.reactKey} path={parentUrl + child.props.url} component={() => child} />;
}

const RoutedTabPanel: React.FC<IProps> = (props: IProps) => {
	const [tabLinks, setTabLinks] = useState<React.ReactElement<RouteProps>[]>([]);
	const [tileLinks, setTileLinks] = useState<React.ReactElement<RouteProps>[]>([]);
	const [routes, setRoutes] = useState<React.ReactElement<RouteProps>[]>([]);

	useEffect(() => {
		if (props.children) {
			const newTabLinks: React.ReactElement<RouteProps>[] = [];
			const newTileLinks: React.ReactElement<RouteProps>[] = [];
			const newRoutes: React.ReactElement<RouteProps>[] = [];
			const tabs: React.ReactElement<IRoutedTabProps>[] = Array.isArray(props.children) ? props.children : [props.children];
			each(tabs, (child: React.ReactElement<IRoutedTabProps>): void => {
				if (child.props.isAllowed !== false) {
					newTabLinks.push(getTabLink(props.match.url, child.props));
					newTileLinks.push(getTileLink(props.match.url, child.props));
					newRoutes.push(getRoute(props.match.url, child));
				}
			});

			setTabLinks(newTabLinks);
			setTileLinks(newTileLinks);
			setRoutes(newRoutes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="routedTabPanel" className="d-flex flex-column">
			<div className="topBar">
				<ul>{tabLinks}</ul>
			</div>
			<div className={"contentContainer d-flex" + (props.match.isExact ? " overview" : "")}>
				<Switch>
					{routes}
					{routes && routes.length > 0 && props.loadFirstTab && <Redirect to={routes[0].props.path as string} />}
					<Route path={props.match.url}>
						<div className="content" style={props.image && { backgroundImage: "url(" + props.image + ")" }}>
							<h1>{props.title}</h1>
							<div className={"tiles d-flex flex-wrap"}>{tileLinks}</div>
						</div>
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default withRouter(RoutedTabPanel);
