import React from "react";

import { Switch, SwitchProps } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

const TranslatedSwitch: React.FC<SwitchProps> = (props: SwitchProps) => {
	const { t } = useTranslation();

	return <Switch {...props} offLabel={t("off")} onLabel={t("on")} />;
};

export default TranslatedSwitch;
