import React from "react";

import { IEntity } from "@selas/models";

interface IAddEntityScreenProps<TEntity extends IEntity> {
	close: (record?: TEntity) => void;
	hideActive?: boolean;
}

interface IEditEntityScreenProps<TEntity extends IEntity> extends IAddEntityScreenProps<TEntity> {
	recordId: number;
	readonly: boolean;
}

interface IEntityCrudConfiguration {
	isAllowed?: boolean;
}

interface IEntityLinkConfiguration extends IEntityCrudConfiguration {
	link: string;
	openInNewTab?: boolean;
}

interface IAddEntityScreenConfiguration<TEntity extends IEntity, TExtraProps = undefined> extends IEntityCrudConfiguration {
	screen: React.ComponentType<IAddEntityScreenProps<TEntity> & TExtraProps>;
	extraProps?: TExtraProps;
}

interface IEditEntityScreenConfiguration<TEntity extends IEntity, TExtraProps = undefined> extends IEntityCrudConfiguration {
	screen: React.ComponentType<IEditEntityScreenProps<TEntity> & TExtraProps>;
	extraProps?: TExtraProps | ((entity: TEntity) => TExtraProps);
}

function isObject<TEntity extends IEntity, TExtraProps>(extraProps: TExtraProps | ((entity: TEntity) => TExtraProps)): extraProps is TExtraProps {
	return typeof extraProps === "object" || typeof extraProps === "undefined";
}

interface IDeleteEntityConfiguration extends IEntityCrudConfiguration {
	urlArguments?: Record<string, unknown>;
}

function isEntityLinkConfiguration(link: string | IEntityLinkConfiguration): link is IEntityLinkConfiguration {
	return (link as IEntityLinkConfiguration).link !== undefined;
}

function isAddScreen<TEntity extends IEntity, TExtraProps = undefined>(
	screenProps: React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TExtraProps>
): screenProps is React.ComponentType<IAddEntityScreenProps<TEntity>> {
	return (screenProps as React.ComponentType<IAddEntityScreenProps<TEntity>>).propTypes?.close !== undefined;
}

function isEditScreen<TEntity extends IEntity, TExtraProps = undefined>(
	screenProps: React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TExtraProps>
): screenProps is React.ComponentType<IEditEntityScreenProps<TEntity>> {
	return (screenProps as React.ComponentType<IEditEntityScreenProps<TEntity>>).propTypes?.close !== undefined;
}

function isDeleteConfiguration(deleteConfig: boolean | IDeleteEntityConfiguration): deleteConfig is IDeleteEntityConfiguration {
	return typeof deleteConfig !== "boolean";
}

function isAddEntityLink<TEntity extends IEntity, ExtraProps = undefined>(
	prop: string | IEntityLinkConfiguration | React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, ExtraProps>
): prop is string | IEntityLinkConfiguration {
	return typeof prop === "string" || (prop as IEntityLinkConfiguration).link !== undefined;
}

function isEditEntityLink<TEntity extends IEntity, TExtraProps = undefined>(
	prop: string | IEntityLinkConfiguration | React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TExtraProps>
): prop is string | IEntityLinkConfiguration {
	return typeof prop === "string" || (prop as IEntityLinkConfiguration).link !== undefined;
}
export {
	IAddEntityScreenConfiguration,
	IAddEntityScreenProps,
	IDeleteEntityConfiguration,
	IEditEntityScreenConfiguration,
	IEditEntityScreenProps,
	IEntityLinkConfiguration,
	isAddEntityLink,
	isAddScreen,
	isDeleteConfiguration,
	isEditEntityLink,
	isEditScreen,
	isEntityLinkConfiguration,
	isObject
};
