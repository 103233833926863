import React from "react";

import { Button, ButtonProps } from "@progress/kendo-react-buttons";

const SubmitButton: React.FC<ButtonProps> = (props: ButtonProps) => (
	<Button {...props} type="submit">
		{props.children}
	</Button>
);

export default SubmitButton;
