import React from "react";

import { cellContentsFunction } from ".";

export function emptyCell(): cellContentsFunction {
	const EmptyCell = () => {
		return <React.Fragment />;
	};

	return EmptyCell;
}
