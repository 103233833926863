import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";

import { cellContentsFunction } from ".";

export function multilineCell(): cellContentsFunction {
	const MultiLineCell = (props: GridCellProps) => {
		const value: string = props.dataItem[props.field];

		return (
			<>
				<pre className="multiline-cell">{value}</pre>
			</>
		);
	};

	return MultiLineCell;
}
