import React from "react";

import { DropDownList, DropDownListProps } from "@progress/kendo-react-dropdowns";
import map from "lodash/map";
import values from "lodash/values";
import { useTranslation } from "react-i18next";

import DropDownListWithEmptyEntry from "./dropDownListWithEmptyEntry";

interface IProps<T> extends DropDownListProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	enum: any;
	value: T;
}

function EnumDropDownList<T>(props: IProps<T>): React.ReactElement {
	const { t } = useTranslation();
	const allProps: DropDownListProps = {
		...props,
		data: map(values(props.enum), (value: string) => {
			return {
				key: value,
				name: t(value)
			};
		}),
		value: props.value && {
			key: props.value,
			name: t(props.value.toString())
		},
		dataItemKey: "key",
		textField: "name"
	};

	if (props.required) {
		return <DropDownList {...allProps} />;
	} else {
		return <DropDownListWithEmptyEntry {...allProps} />;
	}
}

export default EnumDropDownList;
