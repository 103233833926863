import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import { cellContentsFunction } from ".";

export function translatedCell(): cellContentsFunction {
	const TranslatedCell = (props: GridCellProps) => {
		const { t } = useTranslation();
		const value: string = props.dataItem[props.field];

		return <>{t(value)}</>;
	};

	return TranslatedCell;
}
