import React, { useEffect, useState } from "react";

import { Upload, UploadFileInfo, UploadFileRestrictions, UploadFileStatus, UploadOnAddEvent, UploadOnProgressEvent, UploadOnStatusChangeEvent, UploadResponse } from "@progress/kendo-react-upload";
import { IFileData } from "@selas/models";

interface IProps {
	multiple?: boolean;
	initialFiles?: IFileData[];
	accept?: string;
	restrictions?: UploadFileRestrictions;
	showFileList?: boolean;
	onError: (action: string) => void;
	fileUploaded: (response: UploadResponse) => void;
}

const ApiFileUpload: React.FC<IProps> = (props: IProps) => {
	const [files, setFiles] = useState<UploadFileInfo[]>([]);

	useEffect(() => {
		if (props.initialFiles) {
			const initalFiles: UploadFileInfo[] = props.initialFiles.map((file: IFileData) => {
				return {
					uid: file.internalFilename,
					name: file.filename,
					extension: file.filename.split(".").pop(),
					size: file.size,
					progress: 100,
					status: UploadFileStatus.Uploaded
				};
			});
			setFiles(initalFiles);
		}
	}, [props.initialFiles]);

	function onAdd(event: UploadOnAddEvent): void {
		setFiles(event.newState);
	}

	function onProgress(event: UploadOnProgressEvent): void {
		setFiles(event.newState);
	}

	function onStatusChange(event: UploadOnStatusChangeEvent): void {
		const newFile: UploadFileInfo = event.affectedFiles[0];
		if (newFile.status === UploadFileStatus.Uploaded) {
			newFile.uid = event.response.response.internalFilename;
			props.fileUploaded(event.response);
		} else if (newFile.status === UploadFileStatus.UploadFailed || newFile.status === UploadFileStatus.RemoveFailed) {
			props.onError(newFile.status === UploadFileStatus.UploadFailed ? "Upload" : "Remove");
		}
		setFiles(event.newState);
	}

	return (
		<Upload
			batch={false}
			multiple={props.multiple}
			autoUpload
			showFileList={props.showFileList}
			accept={props.accept}
			restrictions={props.restrictions}
			showActionButtons
			withCredentials
			files={files}
			saveUrl="/api/files"
			saveField="file"
			saveMethod="POST"
			onAdd={onAdd}
			onProgress={onProgress}
			onStatusChange={onStatusChange}
		/>
	);
};

export default ApiFileUpload;
