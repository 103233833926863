import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";

import { cellContentsFunction } from ".";

export function dateCell(format = "d"): cellContentsFunction {
	const DateCell = (props: GridCellProps) => {
		const intl: IntlService = useInternationalization();

		function dateToString(date: Date): string {
			if (date) {
				return intl.formatDate(new Date(date), format);
			}
			return "";
		}

		return <>{dateToString(props.dataItem[props.field])}</>;
	};

	return DateCell;
}
