import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { newKey } from "@selas/utils";

import { checkBoxCell } from "./checkboxCell";
import { commandCell } from "./commandCell";
import { dateCell } from "./dateCell";
import { emptyCell } from "./emptyCell";
import { multilineCell } from "./multilineCell";
import { translatedCell } from "./translatedCell";
import { weekCell } from "./weekCell";

export type cellContentsFunction = (props: GridCellProps) => React.ReactNode;
type classNameFunction = (props: GridCellProps) => string;

function customCell(getCellContents?: cellContentsFunction, getCellClassName?: classNameFunction): React.FC<GridCellProps> {
	const CustomCell = (props: GridCellProps) => {
		if (props.rowType === "data") {
			let className: string = props.className;
			if (getCellClassName) {
				if (className) {
					className += " ";
				}
				className = getCellClassName(props);
			}

			return (
				<td key={newKey("col")} className={className}>
					{getCellContents ? getCellContents(props) : props.dataItem[props.field]}
				</td>
			);
		}
		return <React.Fragment />;
	};

	return CustomCell;
}

export { checkBoxCell, commandCell, customCell, dateCell, emptyCell, multilineCell, translatedCell, weekCell };
