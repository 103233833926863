import React from "react";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import find from "lodash/find";
import { useTranslation } from "react-i18next";

export function dropdownFilterCell<T>(data: T[], dataItemKey: keyof T, textField: keyof T, defaultItem?: T): React.FC<GridFilterCellProps> {
	const DropdownFilterCell = (props: GridFilterCellProps) => {
		const { t } = useTranslation();

		function hasValue(value: T): boolean {
			return Boolean(value && value !== defaultItem);
		}

		function onChange(event: DropDownListChangeEvent): void {
			const valueSelected: boolean = hasValue(event.target.value);
			props.onChange({
				value: valueSelected ? event.target.value[dataItemKey] : "",
				operator: valueSelected ? "eq" : "",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: "",
				operator: "",
				syntheticEvent: event
			});
		}

		const selectedValue: unknown = find(data, { [dataItemKey]: props.value });

		return (
			<div className="k-filtercell">
				<DropDownList data={data} dataItemKey={dataItemKey.toString()} textField={textField.toString()} onChange={onChange} value={selectedValue || defaultItem} defaultItem={defaultItem} />
				<button
					className="k-button k-button-icon k-clear-button-visible k-filtercell-operator"
					title={t("grid.filterClearButton")}
					type="button"
					disabled={!hasValue(props.value)}
					onClick={onClearButtonClick}
				>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		);
	};
	return DropdownFilterCell;
}

export default dropdownFilterCell;
