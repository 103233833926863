import React from "react";
import { Route, RouteProps } from "react-router-dom";

interface IProps extends RouteProps {
	isAllowed: boolean;
}

const ProtectedRoute: React.FC<IProps> = (props: IProps) => {
	return <>{props.isAllowed && <Route {...props} />}</>;
};

export default ProtectedRoute;
