import React from "react";

import { DateInputProps, SelectionRange } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { getWeekstring } from "@selas/utils";

function weekInput(week: SelectionRange): React.FC<DateInputProps> {
	const WeekInput = () => {
		return <Input value={getWeekstring(week)} readOnly />;
	};
	return WeekInput;
}

export default weekInput;
