import React from "react";

export interface IStepProps {
	title: string;
	type?: "completed" | "active" | "empty" | "error";
	itemIndex?: number;
	onClick?: (current: number) => void;
}

const Step: React.FC<IStepProps> = (props: IStepProps) => {
	return (
		<div className={"step " + props.type} onClick={() => props.onClick(props.itemIndex)}>
			<div className="title">{props.title}</div>
		</div>
	);
};

export default Step;
