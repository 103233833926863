import React from "react";

import Step, { IStepProps } from "./step";

interface IProps {
	currentStep: number;
	wizardMode: boolean;
	onChildClick: (current: number) => void;
	className?: string;
	children: React.ReactElement<IStepProps>[];
}

const Stepper: React.FC<IProps> = (props: IProps) => {
	return (
		<div className={props.className}>
			{props.children.map((child: React.ReactElement<IStepProps>, index: number) => {
				const stepProps: IStepProps = { ...child.props };
				if (!stepProps.type && props.currentStep === index) {
					stepProps.type = "active";
				} else if (!stepProps.type && (props.currentStep > index || !props.wizardMode)) {
					stepProps.type = "completed";
				} else if (!stepProps.type) {
					stepProps.type = "empty";
				}
				stepProps.itemIndex = index;
				stepProps.onClick = props.onChildClick;
				return React.cloneElement(child, stepProps);
			})}
		</div>
	);
};

export { Step };
export default Stepper;
