import React, { useEffect, useReducer, useState } from "react";

import { AutoCompleteProps, ComboBoxProps, DropDownListProps, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import { useApiService } from "@selas/api-communication";
import { IEntity } from "@selas/models";
import { createEntityReducer, derender, getInitialState, hideLoader, render, showLoader } from "@selas/state-management";
import { newKey } from "@selas/utils";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ISearchBoxProps } from "./searchBox";
import { IAddEntityScreenConfiguration, IAddEntityScreenProps, IEditEntityScreenConfiguration, IEditEntityScreenProps, isAddScreen, isEditScreen, isObject } from "./utils/types";

import "./manageableField.scss";

interface IProps<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined> {
	addScreen: React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>;
	editScreen: React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>;
	fieldLabel: React.ReactNode;
	recordId: number;
	setEntity: (record: TEntity) => void;
	getByIdEndpoint?: string;
	readOnly: boolean;
	children:
		| React.ReactElement<AutoCompleteProps>
		| React.ReactElement<ComboBoxProps>
		| React.ReactElement<DropDownListProps>
		| React.ReactElement<MultiSelectProps>
		| React.ReactElement<ISearchBoxProps<TEntity>>;
}

function ManageableField<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props: IProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps>
): React.ReactElement {
	const [editorKey, setEditorKey] = useState("");
	const [state, dispatch] = useReducer(createEntityReducer<TEntity>(props.getByIdEndpoint), getInitialState<TEntity>());
	const ApiService = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!state.isEntityLoading && state.entity) {
			props.setEntity(state.entity);
			reduxDispatch(derender(editorKey));
			reduxDispatch(hideLoader());
		} else if (state.isEntityLoading) {
			reduxDispatch(showLoader());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isEntityLoading, state.entity]);

	function addRecord(): void {
		const key: string = newKey("add");
		const addScreenProps: IAddEntityScreenProps<TEntity> = {
			close: (record?: TEntity) => hideEditor(key, record),
			hideActive: true
		};
		if (isAddScreen(props.addScreen)) {
			reduxDispatch(render(key, props.addScreen, addScreenProps));
		} else {
			reduxDispatch(
				render(key, props.addScreen.screen, {
					...addScreenProps,
					...props.addScreen.extraProps
				})
			);
		}
	}

	function editRecord(): void {
		const key: string = newKey("edit");
		const editScreenProps: IEditEntityScreenProps<TEntity> = {
			recordId: props.recordId,
			readonly: !props.readOnly,
			close: (record?: TEntity) => hideEditor(key, record),
			hideActive: true
		};
		if (isEditScreen(props.editScreen)) {
			reduxDispatch(render(key, props.editScreen, editScreenProps));
		} else {
			editScreenProps.readonly = editScreenProps.readonly && !props.editScreen.isAllowed;
			reduxDispatch(
				render(key, props.editScreen.screen, {
					...editScreenProps,
					...(isObject(props.editScreen.extraProps) ? props.editScreen.extraProps : undefined)
				})
			);
		}
	}

	function hideEditor(key: string, record?: TEntity): void {
		if (!props.getByIdEndpoint || !record) {
			if (record) {
				props.setEntity(record);
			}
			reduxDispatch(derender(key));
		} else {
			setEditorKey(key);
			ApiService.callApi(dispatch, props.getByIdEndpoint, "GET", { id: record.id });
		}
	}

	return (
		<div className="k-form-field">
			<span className="manageable-field-label">
				{!props.readOnly && (props.addScreen as IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>)?.isAllowed !== false && <i className="las la-plus" onClick={addRecord} />}
				{!props.readOnly && (props.editScreen as IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>)?.isAllowed !== false && props.recordId > 0 && (
					<i className="las la-pencil-alt" onClick={() => editRecord()} />
				)}
				{props.fieldLabel}
			</span>
			{props.children}
		</div>
	);
}

export default ManageableField;
