import React, { useEffect, useState } from "react";

import { isNullOrEmpty } from "@selas/utils";

const TextArea: React.FC<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>> = (
	props: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
) => {
	const [valid, setValid] = useState(!props.required);

	useEffect(() => {
		if (props.required && isNullOrEmpty(props.value?.toString())) {
			setValid(false);
		} else {
			setValid(true);
		}
	}, [props.value, props.required]);

	return <textarea {...props} className={props.className + " k-textarea" + (valid ? "" : " k-state-invalid")} />;
};

export default TextArea;
