import React, { useState } from "react";

import { Dialog, DialogActionsBar, DialogProps } from "@progress/kendo-react-dialogs";
import { IEntity } from "@selas/models";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import StandardButton from "../buttons/standardButton";
import SubmitButton from "../buttons/submitButton";
import Confirm from "../confirm";
import { usePreventWindowUnload } from "../hooks";
import { notify } from "../notification";
import Form from "./form";

interface IProps<T extends IEntity> extends DialogProps {
	dataChanged: boolean;
	close: (record?: T) => void;
	record: T;
	action: string;
	getErrorMessages: () => string[];
	readOnly: boolean;
}

function PageEditor<T extends IEntity>(props: IProps<T>): React.ReactElement {
	const { t } = useTranslation();
	const { dataChanged, close, record, action, getErrorMessages } = props;
	const [askSaveChange, setAskSaveChange] = useState(false);
	const reduxDispatch = useDispatch();

	usePreventWindowUnload(dataChanged);

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function save(): void {
		const errorMessages: string[] = getErrorMessages();
		if (!errorMessages || errorMessages.length <= 0) {
			close(record);
		} else {
			reduxDispatch(
				notify(
					t("error"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"pageEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"error"
				)
			);
		}
	}

	return (
		<>
			<Form>
				<Dialog {...props} onClose={handleClose}>
					{props.children}
					<DialogActionsBar>
						<SubmitButton onClick={handleClose}>{props.readOnly ? t("close") : t("cancel")}</SubmitButton>
						{!props.readOnly && (
							<StandardButton primary onClick={save} disabled={!dataChanged}>
								{action}
							</StandardButton>
						)}
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onDecline={() => close()}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
}

export default PageEditor;
