import React from "react";

import { Button, ButtonProps } from "@progress/kendo-react-buttons";

const StandardButton: React.FC<ButtonProps> = (props: ButtonProps) => (
	<Button {...props} type="button">
		{props.children}
	</Button>
);

export default StandardButton;
