import React from "react";

import { Calendar, CalendarProps, SelectionRange } from "@progress/kendo-react-dateinputs";

import weekPickerCell from "./weekPickerCell";

function weekCalendar(week: SelectionRange): React.FC<CalendarProps> {
	const WeekCalendar = (props: CalendarProps) => {
		return <Calendar {...props} weekNumber focusedDate={week && week.start} cell={weekPickerCell(week)} className={(props.className || "") + " k-week-picker"} />;
	};
	return WeekCalendar;
}

export default weekCalendar;
