import { DatePicker, DatePickerChangeEvent, DateTimePicker, DateTimePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, DropDownList, DropDownListChangeEvent, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBoxChangeEvent, NumericTextBoxHandle, Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { IEntity } from "@selas/models";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import find from "lodash/find";
import set from "lodash/set";

import { EnumRadioGroupChangeEvent } from "../enumRadioGroup";
import { WeekPickerChangeEvent } from "../weekPicker";

function handleChange<T, R>(
	record: T,
	event:
		| React.ChangeEvent<HTMLInputElement>
		| React.ChangeEvent<HTMLTextAreaElement>
		| SwitchChangeEvent
		| ComboBoxCloseEvent
		| NumericTextBoxChangeEvent
		| DatePickerChangeEvent
		| DateTimePickerChangeEvent
		| TimePickerChangeEvent
		| DropDownListChangeEvent
		| EnumRadioGroupChangeEvent<R>
		| WeekPickerChangeEvent
		| MultiSelectChangeEvent
): T {
	const newRecord: Record<string, unknown> = cloneDeep(record) as Record<string, unknown>;
	const target: HTMLInputElement | HTMLTextAreaElement | Switch | ComboBox | NumericTextBoxHandle | DatePicker | DateTimePicker | TimePicker | DropDownList | MultiSelect = event.target;
	const field: string = target.name;

	if (field) {
		if (isDropDown(target)) {
			if (target.value) {
				if (Array.isArray(target.value)) {
					set(newRecord, field, target.value);
				} else {
					set(newRecord, field, target.value[target.props.dataItemKey]);
					if (field.endsWith("Id")) {
						set(newRecord, field.replace(/..$/, ""), target.value);
					}
				}
			} else {
				set(newRecord, field, undefined);
			}
		} else if (isEventWithValue(event)) {
			if (isDateChangeEvent(event) || isWeekChangeEvent(event)) {
				const date: Date = isDateChangeEvent(event) ? event.value : event.value.start;
				set(newRecord, field, date);
			} else {
				set(newRecord, field, event.value);
			}
		} else {
			set(newRecord, field, target.value);
		}
	}

	return newRecord as T;
}

function isDropDown(
	toBeDetermined: HTMLInputElement | HTMLTextAreaElement | Switch | ComboBox | NumericTextBoxHandle | DatePicker | DateTimePicker | TimePicker | DropDownList | MultiSelect
): toBeDetermined is ComboBox | DropDownList | MultiSelect {
	if ((toBeDetermined as ComboBox | DropDownList | MultiSelect).props && (toBeDetermined as ComboBox | DropDownList | MultiSelect).props.dataItemKey) {
		return true;
	}
	return false;
}

function isEventWithValue<R>(
	toBeDetermined:
		| React.ChangeEvent<HTMLInputElement>
		| React.ChangeEvent<HTMLTextAreaElement>
		| SwitchChangeEvent
		| ComboBoxCloseEvent
		| NumericTextBoxChangeEvent
		| DatePickerChangeEvent
		| DateTimePickerChangeEvent
		| TimePickerChangeEvent
		| DropDownListChangeEvent
		| EnumRadioGroupChangeEvent<R>
		| WeekPickerChangeEvent
		| MultiSelectChangeEvent
): toBeDetermined is SwitchChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | DateTimePickerChangeEvent | EnumRadioGroupChangeEvent<R> {
	if (
		(toBeDetermined as
			| SwitchChangeEvent
			| NumericTextBoxChangeEvent
			| DatePickerChangeEvent
			| DateTimePickerChangeEvent
			| TimePickerChangeEvent
			| EnumRadioGroupChangeEvent<R>
			| MultiSelectChangeEvent).value
	) {
		return true;
	}
	return false;
}

function isDateChangeEvent<R>(
	toBeDetermined: SwitchChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | DateTimePickerChangeEvent | TimePickerChangeEvent | EnumRadioGroupChangeEvent<R> | WeekPickerChangeEvent
): toBeDetermined is DatePickerChangeEvent | DateTimePickerChangeEvent | TimePickerChangeEvent {
	if ((toBeDetermined as DatePickerChangeEvent | DateTimePickerChangeEvent | TimePickerChangeEvent).value.getTime) {
		return true;
	}
	return false;
}

function isWeekChangeEvent<R>(toBeDetermined: SwitchChangeEvent | NumericTextBoxChangeEvent | EnumRadioGroupChangeEvent<R> | WeekPickerChangeEvent): toBeDetermined is WeekPickerChangeEvent {
	if ((toBeDetermined as WeekPickerChangeEvent).value.start) {
		return true;
	}
	return false;
}

function getEntity<T extends IEntity>(entities: T[], entityId: number, entity: T): T {
	let result: T = null;
	if (entities && entities.length > 0 && entityId) {
		result = find(entities, (ent: T) => ent.id === entityId);
	}

	// included entityId for clear button
	if (!result && entityId && entity) {
		result = entity;
	}

	return result;
}

function setEntity<TParent, T extends IEntity>(parent: TParent, entity: T, field: keyof TParent, idField: keyof TParent): TParent {
	const newParentRecord: Record<string, unknown> = { ...parent } as Record<string, unknown>;
	if (entity) {
		set(newParentRecord, idField, entity.id);
	} else {
		set(newParentRecord, idField, null);
	}
	set(newParentRecord, field, entity);
	return newParentRecord as TParent;
}

function resetIdsForNewEntities<T extends IEntity>(entitities: T[]): void {
	each(entitities, (entity: T) => {
		if (entity.id <= 0) {
			entity.id = 0;
		}
	});
}

export { getEntity, handleChange, resetIdsForNewEntities, setEntity };
