import React from "react";

import { DropDownList, DropDownListChangeEvent, DropDownListProps } from "@progress/kendo-react-dropdowns";

const DropDownListWithEmptyEntry: React.FC<DropDownListProps> = (props: DropDownListProps) => {
	function onChange(event: DropDownListChangeEvent): void {
		if (event.value && !event.value[props.dataItemKey]) {
			event.value = null;
		}
		props.onChange(event);
	}

	const defaultItem: Record<string, string> = {
		[props.dataItemKey]: null,
		[props.textField]: ""
	};

	return <DropDownList {...props} data={[defaultItem, ...props.data]} onChange={onChange} />;
};

export default DropDownListWithEmptyEntry;
