import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

import { derender, render } from "@selas/state-management";
import { newKey } from "@selas/utils";
import { History, Location } from "history";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Confirm from "../confirm";

interface IProps {
	when: boolean;
	title: string;
	onConfirm: (setNavigationAllowed: (navigationAllowed: boolean) => void) => void;
}

const PendingSavePrompt: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {
	const [nextLocation, setNextLocation] = useState<Location>(null);
	const [navigationAllowed, setNavigationAllowed] = useState(false);
	const history: History = useHistory();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (navigationAllowed && nextLocation) {
			history.push(nextLocation);
		}
	}, [navigationAllowed, history, nextLocation]);

	function showModal(): void {
		const modalKey: string = newKey("modal");
		reduxDispatch(
			render(modalKey, Confirm, {
				title: props.title,
				onConfirm: () => navigate(true, modalKey),
				onDecline: () => navigate(false, modalKey),
				onCancel: () => reduxDispatch(derender(modalKey)),
				children: props.children
			})
		);
	}

	function handleBlockedNavigation(location: Location): boolean {
		if (!navigationAllowed) {
			showModal();
			setNextLocation(location);
			return false;
		}

		return true;
	}

	function navigate(confirmed: boolean, modalKey: string): void {
		reduxDispatch(derender(modalKey));
		if (confirmed) {
			props.onConfirm(setNavigationAllowed);
		} else {
			setNavigationAllowed(true);
		}
	}

	return <Prompt when={props.when} message={handleBlockedNavigation} />;
};

export default PendingSavePrompt;
