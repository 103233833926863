import React, { forwardRef, RefAttributes, useEffect, useImperativeHandle, useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridColumnProps, GridDataStateChangeEvent, GridExpandChangeEvent, GridFilterChangeEvent, GridRowClickEvent, GridRowProps, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { useApiService } from "@selas/api-communication";
import { IEntity } from "@selas/models";
import { createEntityReducer, derender, getInitialState, hideLoader, IEntityState, render, showLoader } from "@selas/state-management";
import { newKey } from "@selas/utils";
import includes from "lodash/includes";
import merge from "lodash/merge";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import StandardButton from "../buttons/standardButton";
import Confirm from "../confirm";
import {
	IAddEntityScreenConfiguration,
	IAddEntityScreenProps,
	IDeleteEntityConfiguration,
	IEditEntityScreenConfiguration,
	IEditEntityScreenProps,
	IEntityLinkConfiguration,
	isAddEntityLink,
	isAddScreen,
	isDeleteConfiguration,
	isEditEntityLink,
	isEditScreen,
	isEntityLinkConfiguration,
	isObject
} from "../editor/utils/types";
import { commandCell, customCell } from "./customCells/gridCells";
import { IAnchorCommand, IFunctionCommand, ILinkCommand, IRecordCommand } from "./customCells/gridCells/commandCell";

function getGridCommandColumn<T>(title: string, commands: (IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T> | IAnchorCommand<T>)[]): React.ReactElement {
	return <GridColumn title={title} cell={customCell(commandCell(commands))} width={Math.max(60, commands.length * 20 + 28) + "px"} filterable={false} editable={false} />;
}

interface IGridPanelProps<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined> {
	listEndpoint: string;
	listUrlArguments?: Record<string, unknown>;
	endpoint?: string;
	children?: React.ReactElement<GridColumnProps>[] | React.ReactElement<GridColumnProps>;
	addScreen?: string | IEntityLinkConfiguration | React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>;
	editScreen?: string | IEntityLinkConfiguration | React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>;
	delete: boolean | IDeleteEntityConfiguration;
	filter?: CompositeFilterDescriptor;
	group?: GroupDescriptor[];
	sort?: SortDescriptor[];
	style?: React.CSSProperties;
	frontActions?: boolean;
	disableDoubleClick?: boolean;
	className?: string;
	selectionField?: string;
	onSelectionChange?: (entity: TEntity) => void;
	extraCommands?: (IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[];
	extraToolbarButtons?: React.ReactElement[];
}

interface IGridPanelRef {
	refresh: () => void;
}

let timeout: NodeJS.Timeout = null;

function GridPanelWithoutRef<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props: IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps>,
	ref: React.Ref<IGridPanelRef>
): React.ReactElement {
	const { onSelectionChange } = props;
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(createEntityReducer<TEntity, IEntityState<TEntity>>(props.endpoint, props.listEndpoint), getInitialState<TEntity>());
	const [commands, setCommands] = useState<(IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[]>([]);
	const initState: State = { skip: 0, take: 25, filter: props.filter, group: props.group, sort: props.sort };
	const [gridState, setGridState] = useState<State>(initState);
	const [displayFilter, setDisplayFilter] = useState(props.filter);
	const [entities, setEntities] = useState<TEntity[]>([]);
	const [selectedId, setSelectedId] = useState(-1);
	const [deleted, setDeleted] = useState(false);
	const apiService = useApiService();
	const reduxDispatch: Dispatch = useDispatch();
	const history = useHistory();

	function refreshGrid() {
		setSelectedId(-1);
		if (onSelectionChange) {
			onSelectionChange(null);
		}
		apiService.callApi(dispatch, props.listEndpoint, "POST", { ...props.listUrlArguments }, gridState);
	}

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refreshGrid();
		}
	}));

	useEffect(() => {
		const newCommands: (IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[] = props.extraCommands || [];
		if (props.delete === true || (isDeleteConfiguration(props.delete) && props.delete.isAllowed !== false)) {
			newCommands.unshift({
				tooltip: t("remove"),
				iconClass: "las la-times",
				idAction: deleteEntity
			});
		}

		if (props.editScreen) {
			if (isEditEntityLink(props.editScreen)) {
				if (typeof props.editScreen === "string" || (isEntityLinkConfiguration(props.editScreen) && props.editScreen.isAllowed !== false)) {
					let editLink = "";
					let openInNewTab = false;
					if (isEntityLinkConfiguration(props.editScreen)) {
						editLink = props.editScreen.link;
						openInNewTab = props.editScreen.openInNewTab;
					}
					newCommands.unshift({
						tooltip: t("edit"),
						iconClass: "las la-pencil-alt",
						link: editLink,
						newTab: openInNewTab
					});
				}
			} else if (isEditScreen(props.editScreen) || (!isEditScreen(props.editScreen) && props.editScreen.isAllowed)) {
				newCommands.unshift({
					tooltip: t("edit"),
					iconClass: "las la-pencil-alt",
					recordAction: editEntity
				});
			}
		}
		setCommands(newCommands);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		refreshGrid();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridState, props.listEndpoint]);

	useEffect(() => {
		if (deleted) {
			setDeleted(false);
			refreshGrid();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleted]);

	useEffect(() => {
		if (state.isListLoading || state.isDeleting) {
			reduxDispatch(showLoader());
		} else if (!state.isListLoading && !state.isDeleting) {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, state.isDeleting, state.isListLoading]);

	useEffect(() => {
		if (state.list) {
			setEntities(state.list);
		}
	}, [state.list]);

	function addEntity(): void {
		if (props.addScreen && !isAddEntityLink(props.addScreen)) {
			const key: string = newKey("add_" + typeof state.entity);
			const addScreenProps: IAddEntityScreenProps<TEntity> = {
				close: (record?: TEntity) => hideEditor(key, record),
				hideActive: false
			};
			if (isAddScreen(props.addScreen)) {
				reduxDispatch(render(key, props.addScreen, addScreenProps));
			} else {
				reduxDispatch(
					render(key, props.addScreen.screen, {
						...addScreenProps,
						...props.addScreen.extraProps
					})
				);
			}
		}
	}

	function editEntity(record: TEntity): void {
		if (props.editScreen && !isEditEntityLink(props.editScreen)) {
			const key: string = newKey("edit");
			const editScreenProps: IEditEntityScreenProps<TEntity> = {
				recordId: record.id,
				readonly: false,
				close: (record?: TEntity) => hideEditor(key, record),
				hideActive: false
			};
			if (isEditScreen(props.editScreen)) {
				reduxDispatch(render(key, props.editScreen, editScreenProps));
			} else {
				editScreenProps.readonly = editScreenProps.readonly || !props.editScreen.isAllowed;
				reduxDispatch(
					render(key, props.editScreen.screen, {
						...editScreenProps,
						...(isObject(props.editScreen.extraProps) ? props.editScreen.extraProps : props.editScreen.extraProps(record))
					})
				);
			}
		} else if (props.editScreen && isEditEntityLink(props.editScreen)) {
			let link = "";
			let openNewInNew = false;
			if (isEntityLinkConfiguration(props.editScreen)) {
				link = props.editScreen.link;
				if (props.editScreen.openInNewTab) {
					openNewInNew = true;
				}
			} else {
				link = props.editScreen;
			}
			link = link.replace(":id", record.id.toString());
			if (openNewInNew) {
				window.open("#" + link);
			} else {
				history.push(link);
			}
		}
	}

	function deleteEntity(id: number): void {
		const confirmKey: string = newKey("confirm");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title", { action: t("remove").toLowerCase() }),
				children: t("confirm_content", { action: t("remove").toLowerCase() }),
				onDecline: () => reduxDispatch(derender(confirmKey)),
				onConfirm: () => {
					reduxDispatch(derender(confirmKey));
					let deleteArguments: Record<string, unknown>;
					if (isDeleteConfiguration(props.delete)) {
						deleteArguments = props.delete.urlArguments;
					}
					apiService.callApi(dispatch, props.endpoint, "DELETE", merge({ id }, deleteArguments), null, null, () => setDeleted(true));
				}
			})
		);
	}

	function onExpandChange(event: GridExpandChangeEvent): void {
		event.dataItem[event.target.props.expandField] = event.value;
		setEntities([...entities]);
	}

	function onRowClick(event: GridRowClickEvent): void {
		setSelectedId(props.selectionField ? event.dataItem[props.selectionField] : event.dataItem.id);
		if (props.onSelectionChange) {
			props.onSelectionChange(event.dataItem);
		}
	}

	function rowRender(row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactElement {
		const htmlRowProps: HTMLTableRowElement = { ...row.props };
		const isSelected: boolean = (props.selectionField ? rowProps.dataItem[props.selectionField] : rowProps.dataItem.id) === selectedId;
		if (rowProps.rowType === "data" && isSelected && !includes(htmlRowProps.className, "k-state-selected")) {
			htmlRowProps.className += " k-state-selected";
		} else if (rowProps.rowType === "data" && isSelected && includes(htmlRowProps.className, "k-state-selected")) {
			htmlRowProps.className = htmlRowProps.className.replace("k-state-selected", "");
		}
		return React.cloneElement(row, htmlRowProps, row.props.children);
	}

	function hideEditor(key: string, record: TEntity): void {
		reduxDispatch(derender(key));
		if (record) {
			refreshGrid();
		}
	}

	const toolbarButtons: React.ReactElement[] = [];
	if (props.addScreen) {
		if (isAddEntityLink(props.addScreen)) {
			let link = null;
			if (!isEntityLinkConfiguration(props.addScreen)) {
				link = props.addScreen;
			} else if (props.addScreen.isAllowed) {
				link = props.addScreen.link;
			}
			if (link) {
				toolbarButtons.push(
					<Link key="add_toolbarkey" to={link}>
						<StandardButton primary>{t("add")}</StandardButton>
					</Link>
				);
			}
		} else if (isAddScreen(props.addScreen) || (!isAddScreen(props.addScreen) && props.addScreen.isAllowed)) {
			toolbarButtons.push(
				<StandardButton key="add_toolbarkey" primary onClick={addEntity}>
					{t("add")}
				</StandardButton>
			);
		}
	}
	if (props.extraToolbarButtons && props.extraToolbarButtons.length > 0) {
		toolbarButtons.push(...props.extraToolbarButtons);
	}

	return (
		<Grid
			className={"noTopBorder flex-grow-1 flex-shrink-1 " + props.className || ""}
			style={{ ...props.style }}
			total={state.totalCount}
			{...gridState}
			filter={displayFilter}
			onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.data)}
			onRowDoubleClick={
				!props.disableDoubleClick
					? (event: GridRowDoubleClickEvent) => editEntity(event.dataItem)
					: () => {
							return;
					  }
			}
			onExpandChange={onExpandChange}
			onRowClick={onRowClick}
			rowRender={rowRender}
			expandField="expanded"
			data={entities}
			filterable
			sortable
			pageable={{ pageSizes: [10, 25, 50, 100] }}
			scrollable="scrollable"
			onFilterChange={(event: GridFilterChangeEvent) => {
				clearTimeout(timeout);
				timeout = setTimeout(
					() =>
						setGridState((oldState: State) => {
							return { ...oldState, filter: event.filter };
						}),
					500
				);
				setDisplayFilter(event.filter);
			}}
		>
			<GridToolbar>
				<div className={(toolbarButtons && toolbarButtons.length > 0 ? "" : "noButtons ") + "toolbarButtonContainer d-flex width-100 align-items-center"}>
					{toolbarButtons}
					<div className="flex-grow-1" />
					<i className="refreshButton las la-sync" onClick={refreshGrid} />
				</div>
			</GridToolbar>

			{commands.length > 0 && props.frontActions && getGridCommandColumn(t("actions"), commands)}
			{props.children}
			{commands.length > 0 && !props.frontActions && getGridCommandColumn(t("actions"), commands)}
		</Grid>
	);
}

type GridPanelType = <TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props: IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps> & RefAttributes<IGridPanelRef>
) => React.ReactElement;
const GridPanel: GridPanelType = forwardRef(GridPanelWithoutRef) as GridPanelType;

export { getGridCommandColumn };
export type { IGridPanelRef };
export default GridPanel;
