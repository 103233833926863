import React, { useEffect, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import each from "lodash/each";

interface IMenuItemProps {
	itemKey: string;
	iconClass: string;
	label: string;
	display: "start" | "end";
	isAllowed?: boolean;
}

interface IMenuLinkProps extends IMenuItemProps {
	url: string;
	exact: boolean;
}

interface IMenuClikkerProps extends IMenuItemProps {
	onClick: () => void;
}

export const MenuItem: React.FC<IMenuLinkProps | IMenuClikkerProps> = () => {
	return <React.Fragment />;
};

interface IMenuProps {
	logoStyle: { backgroundImage: string; backgroundSize: string };
	title: string;
	children: React.ReactElement<IMenuLinkProps | IMenuClikkerProps>[];
}

function isMenuLink(itemProps: IMenuLinkProps | IMenuClikkerProps): itemProps is IMenuLinkProps {
	return (itemProps as IMenuLinkProps).url !== undefined;
}

function getListItem(
	key: string,
	label: string,
	iconClass: string,
	sidebarLink: boolean,
	currentHoverKey: string,
	setCurrentHoverKey: (key: string) => void,
	setMenuBarOpen: (open: boolean) => void,
	onClick?: () => void,
	reactKey?: string
): JSX.Element {
	return (
		<li
			key={reactKey}
			className={currentHoverKey && currentHoverKey === key ? "hover" : ""}
			onMouseEnter={() => {
				setCurrentHoverKey(key);
				if (sidebarLink) {
					setMenuBarOpen(true);
				}
			}}
			onMouseLeave={() => setCurrentHoverKey("")}
			onClick={() => {
				setMenuBarOpen(false);
				if (onClick) {
					onClick();
				}
			}}
		>
			{!sidebarLink && label}
			{sidebarLink && <div className="highlight" />}
			{sidebarLink && <i className={iconClass} />}
		</li>
	);
}

function getLink(
	item: IMenuLinkProps | IMenuClikkerProps,
	sidebarLink: boolean,
	currentHoverKey: string,
	setCurrentHoverKey: (key: string) => void,
	setMenuBarOpen: (open: boolean) => void
): JSX.Element {
	const reactKey = (sidebarLink ? "side" : "main") + "_" + item.itemKey;
	if (isMenuLink(item)) {
		return (
			<NavLink key={reactKey} to={item.url} exact={item.exact} activeClassName="active">
				{getListItem(item.itemKey, item.label, item.iconClass, sidebarLink, currentHoverKey, setCurrentHoverKey, setMenuBarOpen)}
			</NavLink>
		);
	}
	return getListItem(item.itemKey, item.label, item.iconClass, sidebarLink, currentHoverKey, setCurrentHoverKey, setMenuBarOpen, item.onClick, reactKey);
}

const Menu: React.FC<IMenuProps> = (props: IMenuProps) => {
	const [menuBarOpen, setMenuBarOpen] = useState(false);
	const [currentHoverKey, setCurrentHoverKey] = useState("");
	const [sideItems, setSideItems] = useState({ start: [], end: [] });
	const [mainItems, setMainItems] = useState({ start: [], end: [] });

	useEffect(() => {
		const newSideItems: { start: React.ReactElement<NavLinkProps>[]; end: React.ReactElement<NavLinkProps>[] } = { start: [], end: [] };
		const newMainItems: { start: React.ReactElement<NavLinkProps>[]; end: React.ReactElement<NavLinkProps>[] } = { start: [], end: [] };
		each(props.children, (child: React.ReactElement<IMenuLinkProps | IMenuClikkerProps>) => {
			if (child.props.isAllowed !== false) {
				switch (child.props.display) {
					case "start":
						newSideItems.start.push(getLink(child.props, true, currentHoverKey, setCurrentHoverKey, setMenuBarOpen));
						newMainItems.start.push(getLink(child.props, false, currentHoverKey, setCurrentHoverKey, setMenuBarOpen));
						break;
					case "end":
						newSideItems.end.push(getLink(child.props, true, currentHoverKey, setCurrentHoverKey, setMenuBarOpen));
						newMainItems.end.push(getLink(child.props, false, currentHoverKey, setCurrentHoverKey, setMenuBarOpen));
				}
			}
		});
		setSideItems(newSideItems);
		setMainItems(newMainItems);
	}, [props.children, currentHoverKey]);

	return (
		<div className={"sidebar" + (menuBarOpen ? " open" : "")}>
			<div className="menu" onMouseLeave={() => setCurrentHoverKey("")}>
				<div className="main-menu" onMouseEnter={() => setMenuBarOpen(true)} onMouseLeave={() => setMenuBarOpen(false)}>
					<ul>
						<li className="logo" style={props.logoStyle} />
						{sideItems.start}
						<li className="filler" />
						{sideItems.end}
					</ul>
				</div>
				<div className="sub-menu" onMouseEnter={() => setMenuBarOpen(true)} onMouseLeave={() => setMenuBarOpen(false)}>
					<ul>
						<li>{props.title}</li>
						{mainItems.start}
						<li className="filler" />
						{mainItems.end}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Menu;
