import React, { useState } from "react";

import { IFileData } from "@selas/models";
import map from "lodash/map";

import ImageGalleryModal from "./imageGalleryModal";

interface IProps {
	images: (IFileData | string)[];
	imageTileSize: number;
	onDelete?: (fileName: string) => void;
}

const ImageGallery: React.FC<IProps> = (props: IProps) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(null);

	function isFileData(toBeDetermined: IFileData | string): toBeDetermined is IFileData {
		return (toBeDetermined as IFileData).internalFilename !== undefined;
	}

	function getSelectedImageUrl(): string {
		const selectedImage: IFileData | string = props.images[selectedImageIndex];
		return isFileData(selectedImage) ? "/api/files/" + selectedImage.internalFilename + "/" + selectedImage.filename : selectedImage;
	}

	return (
		<div className="galleryContainer">
			<div className="galleryGrid">
				{map(props.images, (image: IFileData | string, index: number) => {
					const url: string = isFileData(image) ? "/api/files/" + image.internalFilename + "/" + image.filename : image;
					return (
						<div key={index} className="tileContainer">
							<div className="tile" style={{ height: props.imageTileSize + "px", width: props.imageTileSize + "px" }} onClick={() => setSelectedImageIndex(index)}>
								<div className="thumbnail" style={{ backgroundImage: "url('" + url + "')" }} key={url} />
								<i className="las la-search viewIcon" style={{ fontSize: Math.round(props.imageTileSize * 0.27) + "px" }} />
							</div>
							{props.onDelete && <i className="las la-trash-alt deleteIcon" onClick={() => props.onDelete(isFileData(image) ? image.internalFilename : image)} />}
						</div>
					);
				})}
			</div>
			{selectedImageIndex !== null && (
				<ImageGalleryModal
					clickClose={() => setSelectedImageIndex(null)}
					clickNext={() => setSelectedImageIndex(selectedImageIndex + 1)}
					clickPrevious={() => setSelectedImageIndex(selectedImageIndex - 1)}
					first={selectedImageIndex === 0}
					last={selectedImageIndex === props.images.length - 1}
					src={getSelectedImageUrl()}
				/>
			)}
		</div>
	);
};

export default ImageGallery;
