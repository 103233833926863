import React from "react";

import { CalendarCell, CalendarCellProps, SelectionRange } from "@progress/kendo-react-dateinputs";
import dayjs from "dayjs";

function weekPickerCell(week: SelectionRange): React.FC<CalendarCellProps> {
	const WeekPickerCell = (props: CalendarCellProps) => {
		const newProps: CalendarCellProps = {
			...props
		};
		if (week) {
			let style = "";
			let selected = false;
			if (dayjs(props.value).isSame(week.start)) {
				selected = true;
				style = " k-range-start";
			} else if (dayjs(props.value).isSame(week.end)) {
				selected = true;
				style = " k-range-end";
			} else if (dayjs(props.value).isAfter(week.start) && dayjs(props.value).isBefore(week.end)) {
				style = " k-range-mid";
			}
			newProps.isSelected = selected;
			newProps.className += (props.className || "") + style;
		}
		return <CalendarCell {...newProps} />;
	};
	return WeekPickerCell;
}

export default weekPickerCell;
