import React from "react";

import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import WeekPicker, { WeekPickerChangeEvent } from "../../../editor/weekPicker";

import "../../../../styles/basics.scss";

export function weekPickerFilterCell(): React.FC<GridFilterCellProps> {
	const WeekPickerFilterCell = (props: GridFilterCellProps) => {
		const { t } = useTranslation();

		function onChange(event: WeekPickerChangeEvent): void {
			const filterDate: Date = event.value.start;
			props.onChange({
				value: new Date(filterDate.getTime() - filterDate.getTimezoneOffset() * 60000),
				operator: "eq",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: null,
				operator: "",
				syntheticEvent: event
			});
		}

		return (
			<div className="k-filtercell">
				<WeekPicker name="filterWeek" value={props.value} onChange={onChange} className="full-width-field" />
				<div className="k-filtercell-operator">
					<button className={"k-button k-button-icon" + (props.value && " k-clear-button-visible")} title={t("grid.filterClearButton")} type="button" onClick={onClearButtonClick}>
						<span className="k-icon k-i-filter-clear" />
					</button>
				</div>
			</div>
		);
	};

	return WeekPickerFilterCell;
}

export default weekPickerFilterCell;
