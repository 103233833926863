import React, { FormEvent } from "react";

const Form: React.FC<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>> = (
	props: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
) => {
	function onSubmit(event: FormEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}
	return (
		<form noValidate onSubmit={onSubmit} {...props}>
			{props.children}
		</form>
	);
};

export default Form;
