import React, { useEffect, useState } from "react";

import { DatePicker, DatePickerChangeEvent, SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { getWeekRange } from "@selas/utils";

import weekCalendar from "./weekCalendar";
import weekInput from "./weekInput";

import "./weekPicker.scss";

interface IProps {
	value: Date;
	name?: string;
	className?: string;
	onChange?: (event: WeekPickerChangeEvent) => void;
	disabled?: boolean;
}

export interface WeekPickerChangeEvent {
	syntheticEvent: React.SyntheticEvent;
	value: SelectionRange | null;
	show: boolean;
	target: DatePicker;
}

const WeekPicker: React.FC<IProps> = (props: IProps) => {
	const [week, setWeek] = useState<SelectionRange>();
	const intl: IntlService = useInternationalization();

	useEffect(() => {
		if (props.value) {
			setWeek(getWeekRange(props.value, intl.firstDay()));
		}
	}, [intl, props.value]);

	function onChange(event: DatePickerChangeEvent): void {
		const newWeek: SelectionRange = getWeekRange(event.value, intl.firstDay());
		if (props.onChange) {
			const newEvent: WeekPickerChangeEvent = {
				value: newWeek,
				syntheticEvent: event.syntheticEvent,
				show: event.show,
				target: event.target
			};
			props.onChange(newEvent);
		} else {
			setWeek(newWeek);
		}
	}

	return <DatePicker name={props.name} className={props.className} dateInput={weekInput(week)} calendar={weekCalendar(week)} onChange={onChange} disabled={props.disabled} />;
};

export default WeekPicker;
