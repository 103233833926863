import React from "react";

export interface IProps {
	label: string;
	reactKey: string;
	className?: string;
}

const Tab: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {
	return <>{props.children}</>;
};

export default Tab;
