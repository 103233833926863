import React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

import StandardButton from "../buttons/standardButton";

interface IProps {
	title?: string;
	onDecline: () => void;
	onConfirm: () => void;
	onCancel?: () => void;
}

const Confirm: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {
	const { t } = useTranslation();

	return (
		<Dialog title={props.title || t("confirm_title")} width="400px" closeIcon={false}>
			<div className="confirm-content">{props.children}</div>
			<DialogActionsBar>
				<StandardButton primary onClick={props.onConfirm}>
					{t("yes")}
				</StandardButton>
				<StandardButton onClick={props.onDecline}>{t("no")}</StandardButton>
				{props.onCancel && <StandardButton onClick={props.onCancel}>{t("cancel")}</StandardButton>}
			</DialogActionsBar>
		</Dialog>
	);
};

export default Confirm;
