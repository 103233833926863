import React from "react";

import { MultiSelect, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import map from "lodash/map";
import values from "lodash/values";
import { useTranslation } from "react-i18next";

interface IProps<T> extends MultiSelectProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	enum: any;
	value: T[];
}

function EnumMultiSelect<T>(props: IProps<T>): React.ReactElement {
	const { t } = useTranslation();

	return (
		<MultiSelect
			{...props}
			data={map(values(props.enum), (value: string) => {
				return {
					key: value,
					name: t(value)
				};
			})}
			value={
				props.value &&
				map(values(props.value), (value: string) => {
					return {
						key: value,
						name: t(value)
					};
				})
			}
			dataItemKey="key"
			textField="name"
		/>
	);
}

export default EnumMultiSelect;
